// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ideck-js": () => import("./../../../src/pages/ideck.js" /* webpackChunkName: "component---src-pages-ideck-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-p-news-card-js": () => import("./../../../src/pages/p/news-card.js" /* webpackChunkName: "component---src-pages-p-news-card-js" */),
  "component---src-pages-p-news-js": () => import("./../../../src/pages/p/news.js" /* webpackChunkName: "component---src-pages-p-news-js" */),
  "component---src-pages-p-press-releases-js": () => import("./../../../src/pages/p/press-releases.js" /* webpackChunkName: "component---src-pages-p-press-releases-js" */),
  "component---src-pages-p-press-releases-slug-js": () => import("./../../../src/pages/p/press-releases/slug.js" /* webpackChunkName: "component---src-pages-p-press-releases-slug-js" */),
  "component---src-pages-p-press-releases-spaceit-recognized-as-a-game-changer-for-real-estate-professionals-js": () => import("./../../../src/pages/p/press-releases/spaceit-recognized-as-a-game-changer-for-real-estate-professionals.js" /* webpackChunkName: "component---src-pages-p-press-releases-spaceit-recognized-as-a-game-changer-for-real-estate-professionals-js" */),
  "component---src-pages-p-press-room-masthead-js": () => import("./../../../src/pages/p/press-room-masthead.js" /* webpackChunkName: "component---src-pages-p-press-room-masthead-js" */),
  "component---src-pages-presentation-js": () => import("./../../../src/pages/presentation.js" /* webpackChunkName: "component---src-pages-presentation-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

